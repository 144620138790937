import { CreatableSelect } from '@/components/form/common/creatable-select';
import { Select } from '@/components/form/common/select';
import { TextInput } from '@/components/form/common/text-input';
import { applicationTemplateKey } from '@/config/query-keys';
import { useCreateAdditionalQuestionMutation } from '@/hooks/create-hooks/use-create-additional-question-mutation';
import { useDeleteAdditionalQuestionMutation } from '@/hooks/delete-hooks/use-delete-additional-question';
import { useUpdateAdditionalQuestion } from '@/hooks/update-hooks/update-additional-question';
import { queryClient } from '@/libs/react-query';
import {
  AdditionalQuestion,
  ApplicationTemplate,
} from '@admissions-support/types';
import { Trash01 } from '@untitled-ui/icons-react';
import { FormProvider } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { AdditionalQuestionFormData } from '../additional-question.type';
import { useAdditionalQuestionForm } from '../use-additional-question.form';

type AdditionalQuestionFormProps = {
  onClose: () => void;
  initialData?: AdditionalQuestion;
};

function AdditionalQuestionForm({
  onClose,
  initialData,
}: AdditionalQuestionFormProps) {
  const { form } = useAdditionalQuestionForm({ initialData });
  const params = useParams<{ id?: string }>();

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateAdditionalQuestion({
      onSuccess: updatedData => {
        queryClient.setQueryData<ApplicationTemplate>(
          applicationTemplateKey.single(params.id || ''),
          updatedData
        );
      },
    });

  const { mutateAsync: create, isPending: isCreating } =
    useCreateAdditionalQuestionMutation({
      onSuccess: createdData => {
        queryClient.setQueryData<ApplicationTemplate>(
          applicationTemplateKey.single(params.id || ''),
          createdData
        );
      },
    });

  const { mutateAsync: deleteQuestion, isPending: isDeleting } =
    useDeleteAdditionalQuestionMutation({
      onSuccess: async () => {
        const listingQuery = queryClient.getQueryData<ApplicationTemplate>(
          applicationTemplateKey.single(params.id || '')
        );

        if (!listingQuery) {
          return;
        }

        await queryClient.setQueryData<ApplicationTemplate>(
          applicationTemplateKey.single(params.id || ''),
          {
            ...listingQuery,
            additionalQuestions: listingQuery.additionalQuestions.filter(
              aq => aq.id !== initialData?.id
            ),
          }
        );
      },
    });

  const handleSubmit = async (data: AdditionalQuestionFormData) => {
    const type = data.type;
    const isOptionsAllowed =
      type === 'radio' || type === 'checkbox' || type === 'select';

    if (initialData) {
      await update({
        templateId: params.id || '',
        questionId: initialData.id,
        data: {
          label: data.label,
          type,
          options: isOptionsAllowed ? data.options.map(o => o.value) : [],
        },
      });
    } else {
      await create({
        templateId: params.id || '',
        data: {
          label: data.label,
          type,
          options: isOptionsAllowed ? data.options.map(o => o.value) : [],
        },
      });
    }
    onClose();
  };

  const handleDeleteQuestion = async () => {
    console.log(initialData);

    await deleteQuestion({
      templateId: params.id || '',
      questionId: initialData?.id || '',
    });
    onClose();
  };

  const type = form.watch('type');
  const isOptionsAllowed =
    type === 'radio' || type === 'checkbox' || type === 'select';

  const isNewQuestion = !initialData;

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
        <div className="mb-4 flex items-center justify-between">
          <p className="text-lg font-medium">
            {isNewQuestion ? 'Add question' : 'Edit question'}
          </p>
          <div className="flex space-x-2">
            {!isNewQuestion && (
              <button
                className="btn btn-error btn-sm btn-icon"
                type="button"
                onClick={handleDeleteQuestion}
                disabled={isDeleting}
              >
                <Trash01
                  className="mr-2 h-4 w-4"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                />
                Remove
              </button>
            )}
            {isNewQuestion && (
              <button
                className="btn btn-secondary btn-sm"
                disabled={isUpdating}
                type="button"
                onClick={onClose}
              >
                Cancel
              </button>
            )}
            <button
              className={twMerge('btn btn-secondary btn-sm')}
              disabled={isUpdating || isCreating}
            >
              Save
            </button>
          </div>
        </div>

        <TextInput
          type="text"
          name="label"
          label="Question Label*"
          placeholder="Type question"
        />
        <Select
          name="type"
          label="Question Type"
          placeholder="Select.."
          className="w-full"
          options={[
            { value: 'Select...', key: '' },
            { value: 'Checkbox', key: 'checkbox' },
            { value: 'Radio', key: 'radio' },
            {
              value: 'paragraph text',
              key: 'textarea',
            },
            {
              value: 'Text input',
              key: 'input',
            },
          ]}
        />

        {isOptionsAllowed && (
          <CreatableSelect
            name="options"
            label="Add option"
            placeholder="Type input option..."
            formatCreateLabel={value => `Add: ${value}`}
            noOptionsMessage={() => 'Start typing...'}
            options={[]}
            isMulti
          />
        )}
      </form>
    </FormProvider>
  );
}

export { AdditionalQuestionForm };
